import "babel-polyfill"
import React from "react"

// Styling
import CSSReset from "./src/styles/reset"
import GlobalStyle from "./src/styles"

const COMMIT = process.env.COMMIT_HASH || "N.A."
const VERSION = process.env.VERSION || "N.A."

export const onInitialClientRender = () => {
  console.info(`
    __            __        _                __   __
     | |  | |\\ | / _\` |    |__  |\\/| | |\\ | |  \\ /__\`
  \\__/ \\__/ | \\| \\__> |___ |___ |  | | | \\| |__/ .__/
  by: Jungle Minds
  Version: ${VERSION}
  Commit: ${COMMIT}
  `)
}

/* eslint-disable react/prop-types */
export const wrapRootElement = ({ element }) => (
  <>
    <CSSReset />
    <GlobalStyle />
    <>{element}</>
  </>
)
