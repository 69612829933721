// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-details-tsx": () => import("/opt/build/repo/src/pages/details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-results-tsx": () => import("/opt/build/repo/src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("/opt/build/repo/src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-themes-tsx": () => import("/opt/build/repo/src/pages/themes.tsx" /* webpackChunkName: "component---src-pages-themes-tsx" */),
  "component---src-pages-themes-components-tsx": () => import("/opt/build/repo/src/pages/themes/components.tsx" /* webpackChunkName: "component---src-pages-themes-components-tsx" */),
  "component---src-pages-themes-front-end-code-tsx": () => import("/opt/build/repo/src/pages/themes/front-end-code.tsx" /* webpackChunkName: "component---src-pages-themes-front-end-code-tsx" */),
  "component---src-pages-themes-guidelines-tsx": () => import("/opt/build/repo/src/pages/themes/guidelines.tsx" /* webpackChunkName: "component---src-pages-themes-guidelines-tsx" */),
  "component---src-pages-themes-interaction-patterns-tsx": () => import("/opt/build/repo/src/pages/themes/interaction-patterns.tsx" /* webpackChunkName: "component---src-pages-themes-interaction-patterns-tsx" */),
  "component---src-pages-themes-page-templates-tsx": () => import("/opt/build/repo/src/pages/themes/page-templates.tsx" /* webpackChunkName: "component---src-pages-themes-page-templates-tsx" */)
}

