export default {
  // Gebruiker Centraal design system colors
  white: "#ffffff",
  green: "#25b34b",
  greenDark: "#148839",
  darkBlue: "#004152",
  darkerBlue: "#00303d",
  pantyBrown: "#f2ebe4",
  pantyBrownLight: "#f9f6f3",
  red: "#d94721",

  // Design update colors
  background: "#F8F9FB",
  lightGrey: "#E1E1E1",
  offGrey: "#B3B3B3",
  grey: "#6A6A6A",
  darkGrey: "#2C2C2C",

  // Theme colors
  themePurple: "#4c2974",
  themeBlue: "#0095da",
  themePink: "#c42c76",
  themeOrange: "#f99d1c",
  themeCyan: "#00b4ac",
}
