import { css } from "styled-components"

// Fonts
const fonts = {
  primary: "'Montserrat', sans-serif",
}

const fontFaces = css`
  /* prettier-ignore */
  @import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700,800&display=swap");
`

export { fonts, fontFaces }
