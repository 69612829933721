import { css } from "styled-components"

// Styling
import { fonts } from "./fonts"
import mq from "./breakpoints"

// Text styles
export default {
  title: css`
    margin: 0 0 24px;
    font-family: ${fonts.primary};
    font-size: 38px;
    font-weight: 800;
    line-height: 1.125;

    ${mq.from.breakpoint.S`
      font-size: 58px;
    `};
  `,
  subtitle: css`
    margin: 0 0 24px;
    font-family: ${fonts.primary};
    font-size: 24px;
    font-weight: 800;
    line-height: 1.125;

    ${mq.from.breakpoint.S`
      font-size: 32px;
    `};
  `,
  subtitleSmall: css`
    margin: 0;
    font-family: ${fonts.primary};
    font-size: 18px;
    font-weight: 800;
    line-height: 1.125;

    ${mq.from.breakpoint.S`
      font-size: 24px;
    `};
  `,
  body: css`
    margin: 0 0 16px;
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  `,
  bodySmall: css`
    margin: 0 0 16px;
    font-family: ${fonts.primary};
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  `,
}
